import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { debug, formatDate, swalAlert } from '../../../utils';
import './admin-order-modal-edit-payment.css';
import { FaPlus } from 'react-icons/fa';
function AdminOrderModalEditPayment({ show, onHide, order, onCreatePayment }) {
  // Initialisation de l'état local pour les champs du formulaire

  const [newPayment, setNewPayment] = useState({ 'color': 'blue' });
  const handleChange = () => {
    // Vérification des champs requis

  }


  const handleAddPayment = () => {
    // Vérification des champs requis
    if (newPayment.payDate && newPayment.payMode && newPayment.payAmout) {
      onCreatePayment(newPayment);  // Appelle la fonction onSave avec la commande mise à jour

    } else {
      swalAlert("Tous les champs marqués * sont requis.");
    }
  }
  useEffect(() => {
    setNewPayment({ payDate: '', payMode: '', payAmout: '' });  // Réinitialise le formulaire
  }, [order])

  return (
    <Modal show={show} onHide={onHide} size="md" id="AdminOrderModalEditPayment">
      <Modal.Header closeButton>
        <Modal.Title><strong>{order.ordNumber}</strong></Modal.Title>
      </Modal.Header>
      <Modal.Body>

        <div class="row text-center mb-4">
          <div class="col-sm-6">
            <label className='fs-8 fw-bold'>TOTAL A REGLER</label>
            <input type="number" className='text-center form-control rounded border-light' value={order.ordToBePaid} readOnly={true} onChange={handleChange} name="ordToBePaid" />
          </div>
          <div class="col-sm-6">

            <label className='fs-8 fw-bold'>SOLDE</label>
            <input type="number" className='text-center form-control rounded border-light' value={order.ordBalance} readOnly={true} />

          </div>
        </div>

        <table className='table table-sm'>
          <thead>
            <tr>
              <th className='text-center fs-9'>Date*</th>
              <th className='text-center fs-9'>Mode*</th>
              <th className='text-center fs-9'>Montant*</th>
              <th className='text-center fs-9'>Transac.</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {order.payments.map(p => (
              <tr>
                <td className='text-center'>{formatDate(p.payDate)}</td>
                <td className='text-center' >{p.payMode}</td>
                <td className='text-center'>{p.payAmout}</td>
                <td className='text-center'>{p.payTransac}</td>
                <td className='text-center' >
                </td>
              </tr>
            ))}
            <tr>
              <td className='text-center'>
                <input type='date' className='form-control form-control-sm' name="payDate" value={newPayment.payDate} onChange={e => setNewPayment({ ...newPayment, payDate: e.target.value })} />
              </td>
              <td className='text-center'>
                <select className='form-control form-control-sm' name="payMode" value={newPayment.payMode} onChange={e => setNewPayment({ ...newPayment, payMode: e.target.value })} style={{ width: '80px' }}>
                  <option value=""></option>
                  <option value="CBI">CB en ligne</option>
                  <option value="CBC">CB comptoir</option>
                  <option value="VIR">Virement</option>
                  <option value="CHQ">Chèque</option>
                  <option value="ESP">Espèces</option>
                </select>
              </td>
              <td className='text-center'>
                <input type='number' className='form-control form-control-sm' name="payAmout" value={newPayment.payAmout} onChange={e => setNewPayment({ ...newPayment, payAmout: e.target.value })} />
              </td>
              <td className='text-center'>
                <input type='text' className='form-control form-control-sm' name="payAmout" value={newPayment.payTransac} onChange={e => setNewPayment({ ...newPayment, payTransac: e.target.value })} />
              </td>
              <td className='text-center'>
                <button className='btn btn-sm btn-outline-warning' onClick={handleAddPayment}>
                  <FaPlus />
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </Modal.Body>
      <Modal.Footer>
      </Modal.Footer>
    </Modal>
  );
}

export default AdminOrderModalEditPayment;
