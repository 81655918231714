// AdminCustomerProfile.js
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { debug, formatDate } from './../../../utils'
import DialCodes from '../../helpers/DialCodes/DialCodes';
import Picture from '../../helpers/Picture/Picture';
import PhoneNumber from '../../helpers/PhoneNumber/PhoneNumber';
import API from './../../../api'
const AdminCustomerProfile = ({ customer, onSaved }) => {
  const { t } = useTranslation();
  const user = useSelector((state) => state.user);
  const config = useSelector((state) => state.config);
  const [customerCopy, setCustomerCopy] = useState({});
  const [orders, setOrders] = useState([]);
  const [isNewCustomer, setINewCustomer] = useState(false);
  const [submission, setSubmission] = useState({ status: '' });
  useEffect(() => {
    setINewCustomer(customer.custId === '');
  }, []);
  useEffect(() => {
    setCustomerCopy({
      ...customer,
      email: customer.user.userEmail,
      phoneNumber: customer.user.userPhone
    })
    if (customer.custId !== '') {
      get_customer_orders();
    }
  }, [customer]);
  const get_customer_orders = async () => {
    try {
      const query = { 'ordUserId:eq': customer.custUserId }
      const response = await API.get('/admin/orders?' + 'query=' + JSON.stringify(query))
      setOrders(response.data);
    } catch (e) {
      console.log(e)
    }
  }
  const handleInputChange = (event) => {

    console.log(event.target)

    setSubmission({ status: '' });
    const { name, value } = event.target;
    setCustomerCopy({ ...customerCopy, [name]: value });
  };
  const handleSave = async () => {
    const updatedCustomer = { ...customerCopy };
    updatedCustomer.user.userEmail = customerCopy.email;
    updatedCustomer.user.userPhone = customerCopy.phoneNumber;
    setCustomerCopy({ ...updatedCustomer });
    try {
      const response = updatedCustomer.custId === '' ?
        await API.post('/admin/customer', updatedCustomer) :
        await API.put(`/admin/customer/${updatedCustomer.custId}`, updatedCustomer);
      const customer = {
        ...response.data,
        email: response.data.user.userEmail,
        phoneNumber: response.data.user.userPhone
      };
      setCustomerCopy(customer);
      setSubmission({ status: 'success', message: 'Sauvegarde effectuée avec succès' });
      window.setTimeout(() => {
        setSubmission({ status: '' });

      }
        , 1500);
      onSaved(customer);
    } catch (error) {
      setSubmission({ status: 'error', message: error.response.data.error.message })
    }
  };
  return (
    <div id="AdminCustomerProfile">
      <div className="row">
        <div className="col-sm-6">
          <div className="form-group">
            <label className="form-group-label" required>Prénom : </label>
            <input
              type="text"
              className="form-control form-control-sm"
              value={customerCopy.custFirstname}
              name="custFirstname"
              onChange={handleInputChange}
            />
          </div>
          <div className="form-group">
            <label className="form-group-label" required>Nom : </label>
            <input
              type="text"
              className="form-control form-control-sm"
              value={customerCopy.custName}
              name="custName"
              onChange={handleInputChange}
            />
          </div>
          <div className="form-group">
            <label className="form-group-label" required>Email : </label>
            <input
              type="email"
              className="form-control form-control-sm"
              value={customerCopy.email}
              name="email"
              onChange={handleInputChange}
            />
          </div>
          <div className="form-group">
            <label className="form-group-label" required>Téléphone : </label>
            <PhoneNumber
              disabled={false}
              name="phoneNumber"
              number={customerCopy.phoneNumber}
              onUpdate={phoneNumber => handleInputChange({ target: { name: 'phoneNumber', value: phoneNumber } })}
            />
          </div>

          <div className='form-group'>
            <label className="form-group-label" required>Abonnement : </label>
            <select
              type="text"
              className="form-control form-control-sm"
              value={customerCopy.custSubscription}
              name="custSubscription"
              onChange={handleInputChange}
            >
              <option value="">&gt; Sélectionner</option>
              <option value="none">Non</option>
              <option value="1/semaine">1X / Semaine</option>
              <option value="2/semaine">2X / Semaine</option>
            </select>
          </div>

          <div className='form-group'>
            <label className="form-group-label" required>Envoi automatique de la demande de paiement : </label>
            <select
              type="text"
              className="form-control form-control-sm"
              value={customerCopy.custSendPaymentRequest}
              name="custSendPaymentRequest"
              onChange={handleInputChange}
            >
              <option value="">&gt; Sélectionner</option>
              <option value="1">Oui</option>
              <option value="0">Non</option>
            </select>
          </div>

        </div>
        <div className="col-sm-6">
          <div className="form-group">
            <label className="form-group-label" required>Adresse de facturation : </label>
            <textarea
              style={{ height: '107px' }}
              className="form-control form-control-sm"
              value={customerCopy.custAddress}
              name="custAddress"
              onChange={handleInputChange}
            />
          </div>
          <div className="form-group d-flex">
            <div className='w-25'>
              <label className="form-group-label" required>CP : </label>
              <input
                type="text"
                className="form-control form-control-sm"
                value={customerCopy.custPC}
                name="custPC"
                onChange={handleInputChange}
              />
            </div>
            <div className='flex-grow-1 ps-3'>
              <label className="form-group-label" required>Ville : </label>
              <input
                type="text"
                className="form-control form-control-sm"
                value={customerCopy.custCity}
                name="custCity"
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className='form-group'>
            <label className="form-group-label" >Numéro de carte de fidélité : </label>
            <input
              type="text"
              className="form-control form-control-sm"
              value={customerCopy.custLoyaltyNumber}
              name="custLoyaltyNumber"
              onChange={handleInputChange}
            />
          </div>
          {submission.status == '' &&
            <Picture src={`/msmedias/customers/${customerCopy.custId}/loyaltyQRCode.png`} width={140} />
          }
        </div>
      </div>
      <div className="mb-3"></div>
      <div className={`small py-2 px-3 alert alert-${submission.status == 'error' ? 'danger' : submission.status}`}>
        {submission.message}
      </div>
      <div className="form-group">
        <button type="button" className="btn btn-outline-warning" onClick={handleSave}>Sauvegarder</button>
      </div>
      {debug(customerCopy, 0)}
      {/* Debugging */}
    </div>
  );
};
export default AdminCustomerProfile;


